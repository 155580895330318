<template>
  <section class="policy-list">
    <div
      class="header-title-back title-margin-bottom">
      <span
        class="title-content hand"
        @click="() => $router.back()">Referrer</span>
      <i
        class="el-icon-arrow-right icon-little"
      ></i>
      <span class="title-litter">{{data.agency_code}}</span>
    </div>
    <div class="block-body">
      <div class="block-content">
        <div class="flex flex-justify min-size-text">
          <div class="flex">
            <span class="is-bold ">{{data.agency_code}}</span>
            <font-awesome-icon
              class="size-normal"
              icon="phone"/>
            <span class="size-normal">{{data.contact_number}}</span>
            <v-button
              plain
              class="button-active">Active</v-button>
          </div>
          <div class="flex">
            <!--            <div class="flex">-->
            <!--              <font-awesome-icon-->
            <!--                class="size-normal"-->
            <!--                icon="hand-holding-usd"/>-->
            <!--              <span class="size-normal">10%</span>-->
            <!--            </div>-->
            <div class="flex ">
              <!--              <font-awesome-icon-->
              <!--                class="size-normal"-->
              <!--                icon="coins"/>-->
              <span
                class=" link hand"
                @click="handlerEdit">Edit</span>
            </div>
          </div>
        </div>
        <div class="flex flex-address">
          <div class="table">
            <div></div>
            <div
              class="tr">
              <span>Agency Code</span>
              <span>{{data.agency_code||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Company Name Eng </span>
              <span>{{data.company_name_en||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Company Name Chi</span>
              <span>{{data.company_name_chi||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Tel No</span>
              <span>{{data.tel_no||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Fax No</span>
              <span>{{data.fax_no||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Email</span>
              <span>{{data.eamil||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Commission Rate</span>
              <span>{{data.commission_rate}}%
              </span>
            </div>
            <div
              class="tr">
              <span>Account  Status</span>
              <span>{{getOptionName('yesNo2Options',data.account_status)||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Agency Last ModifiedDate</span>
              <span>{{data.agency_last_modified_date||'-'}}
              </span>
            </div>
          </div>
          <div class="table">
            <div></div>
            <div
              class="tr">
              <span>Contact Person</span>
              <span>{{data.contact_person||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Contact Number</span>
              <span>{{data.contact_number||'-'}}
              </span>
            </div>
            <div
              class="tr">
              <span>Address1</span>
              <span>{{address(0)||'-'}}
              </span>
            </div>
            <!--            <div-->
            <!--              class="tr">-->
            <!--              <span>Account ID</span>-->
            <!--              <span>{{address(0)||'-'}}-->
            <!--              </span>-->
            <!--            </div>-->
            <div
              class="tr">
              <span>Login Password</span>
              <span>*******
              </span>
            </div>
            <!--            <div-->
            <!--              class="tr">-->
            <!--              <span>Address2</span>-->
            <!--              <span>{{address(1)||'-'}}-->
            <!--              </span>-->
            <!--            </div>-->
            <!--            <div-->
            <!--              class="tr">-->
            <!--              <span>Address3</span>-->
            <!--              <span>{{address(2)||'-'}}-->
            <!--              </span>-->
            <!--            </div>-->
            <!--            <div-->
            <!--              class="tr">-->
            <!--              <span>Address4</span>-->
            <!--              <span>{{address(3)||'-'}}-->
            <!--              </span>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick">
        <el-tab-pane
          label="Unsettled"
          name="1"></el-tab-pane>
        <el-tab-pane
          label="Settled"
          name="2"></el-tab-pane>
        <el-tab-pane
          label="Pending"
          name="4"></el-tab-pane>
        <el-tab-pane
          label="Reports"
          name="3"></el-tab-pane>
      </el-tabs>
    </div>

    <policies-sold
      v-if="activeName==1"
      :type="2"
      :isPending="false"
      :summary="summary"
      @refresh="handlerSummaryRefresh"
    ></policies-sold>
    <settlements
      v-else-if="activeName==2"
      :type="2"
    ></settlements>
    <pending-list
      v-else-if="activeName==4"
      :type="2"
      :summary="summary"
    ></pending-list>
    <reports
      v-else-if="activeName==3"
      :type="2"
    ></reports>
    <create-referrer-dialog
      ref="createDialog"
      :form="data"
      :isEdit="true"
      @refresh="handlerRefresh"></create-referrer-dialog>
  </section>
</template>

<script>
import policiesSold from '@/views/member/componment/policiesSold'
import pendingList from '@/views/member/componment/pendingList'
import settlements from '@/views/member/componment/settlementList'
import reports from '../member/componment/reports'
import {getReferrerDetail, editReferrer, getReferrerUnsettleSummary,getReferrerPendingSummary} from '@api/referrer'
import createReferrerDialog from '@/views/components/dialog/createReferrerDialog'
import { mapState, mapActions } from 'vuex'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'

export default {
  name: 'referral',

  components:{
    policiesSold,
    settlements,
    reports,
    createReferrerDialog,
    pendingList
  },
  mixins: [MixinOptions,MixinRules],
  props:{
  },
  data(){
    return {
      activeName:'1',
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      data:{},
      summary:{}
    }
  },
  computed:{
    ...mapState('statement',['statementTab']),
    address() {
      const {address=[]} =this.data||{}
      return (index) =>{
        let data = address[index]||{}
        if(data)
        {
          let arr = [
            data.room?`ROOM ${data.room} `:'',
            data.floor?`${data.floor}/F `:'',
            data.block?`BLOCK ${data.block} `:'',
            data.building,
            data.street,
            this.getAreaName('district', data.district),
            this.getAreaName('area', data.area),
            this.getAreaName('region', data.region)
          ]
          return arr.filter(item => item).join(', ')
        }else  return ''
      }

    },
    passwordStr(){
      return (str) =>{
        if(!str)
          return '-'
        return str.replace(/./g, '*')
      }
    }
  },
  created() {
    // this.type = this.statementTab||'1'
    this.activeName = this.statementTab||'1'
    this.network().getReferrerDetail()
    if(this.activeName==1){
      this.network().getReferrerUnsettleSummary()
    }
    else if(this.activeName==4)
      this.network().getReferrerPendingSummary()
  },
  methods:{
    ...mapActions('statement',['setStatementTab']),
    handlerSummaryRefresh(){
      if(this.activeName==1){
        this.network().getReferrerUnsettleSummary()
      }
      else if(this.activeName==4)
        this.network().getReferrerPendingSummary()
    },
    handlerEdit(){
      let {id} = this.$route.params
      this.$router.push('/member/referral/edit/'+id)
      // this.$refs.createDialog.showReferDialog()
    },
    handlerRefresh(params){
      this.network().editReferrer(params)
    },
    statementRefresh(){
      this.handleClick()
    },
    handleClick() {
      this.setStatementTab(this.activeName)
      this.pageConfig.currentPage = 1
      if(this.activeName==1){
        this.network().getReferrerUnsettleSummary()
      }
      else if(this.activeName==4)
        this.network().getReferrerPendingSummary()
      // this.getList()
    },
    network() {
      return {
        getReferrerDetail: async () => {
          const { data } = await getReferrerDetail({
            referral_id:this.$route.params.id
          })
          this.data = data
          // this.pageConfig.total = data.total
        },
        getReferrerPendingSummary: async () => {
          const { data } = await getReferrerPendingSummary({
            referral_id:this.$route.params.id
          })
          this.summary = data
          // this.pageConfig.total = data.total
        },
        getReferrerUnsettleSummary: async () => {
          const { data } = await getReferrerUnsettleSummary({
            referral_id:this.$route.params.id
          })
          this.summary = data
          // this.pageConfig.total = data.total
        },
        editReferrer: async (params) => {
          params = {
            ...params,
            referral_id:this.$route.params.id
          }
          const { data } = await editReferrer(params)
          this.network().getReferrerDetail()
        }

      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.block-body{
  background: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  .flex{
    align-items: center;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000;
    margin-right: 10px;
  }
  .size-normal{
    font-size: 14px;
    color: #718096;
    margin-right: 8px;
  }
  .block-content{
    padding: 20px;
  }
  ::v-deep .el-tabs__header{
    padding-left: 20px;
    margin: 0;
    .el-tabs__nav-wrap::after{
      height: 0;
    }
  }
}
.title-content{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}
.title-margin-bottom{
  margin-bottom: 40px;
  .icon-little{
    margin: 0 14px;
    font-size: 12px;
  }
}
.flex-content{
  margin-bottom: 20px;
  .title-tip{
    color: #4A5568;
    font-size: 16px;
    font-weight: 600;
    span{
      margin-right: 15px;
    }
  }
}
.flex-address{
  align-items: start!important;
  margin-top: 20px;
  .table{
    flex: 1;
    &:first-child{
      margin-right: 20px;
    }
  }
}
</style>
