<template>
  <section class="policy-list">
    <div class="show-table-maxscreen">
      <div
        v-if="isSelect"
        class="flex-justify flex flex-content">
        <div
          v-if="isAble"
          class="flex title-tip">
        </div>
        <div
          v-else
          class="title-tip">
          <span>{{multipleSelection.length}} Selected</span>
        </div>
        <div class="flex-cloumn flex-button">
          <div class="flex">
            <v-button
              plain
              :class="isAble?'disable-class':''"
              :disabled="isAble"
              @click="handlerClearSelection"
            >Clear Selection</v-button>
            <v-button
              class="button-danger"
              :class="isAble?'disable-class':''"
              :disabled="isAble"
              @click="handlerMark"
            >Undo</v-button>
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-row-class-name="'table-header'"
        style="width: 100%"
        :row-key="getRowId"
        @selection-change="handleSelectionChange"
      >
        <div >
          <el-table-column width="10">
          </el-table-column>
          <el-table-column
            v-if="isSelect"
            :reserve-selection='true'
            type="selection"
            prop="order_no"
            width="55">
          </el-table-column>
          <el-table-column
            width="220"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Issue Date</span>
                <v-date-picker
                  v-model="issueDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"
                  @change="handleChange"
                ></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.issue_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="220"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Effective Date</span>
                <v-date-picker
                  v-model="effectiveDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"
                  @change="handleChange"
                ></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.effective_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="220"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Settle Date</span>
                <v-date-picker
                  v-model="settleDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"
                  @change="handleChange"
                ></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.settle_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==1"
            width="220"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Expiry Date</span>
                <v-date-picker
                  v-model="expiryDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.expiry_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Insurer</span>
                <v-select
                  v-model="company_id"
                  :list="companyList"
                  @change="handleChange"></v-select>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="flex flex-logo">
                <span
                  v-if="showOtherInsurer(scope.row.company_id)"
                  class="company-logo">
                  <img
                    :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"
                    alt="company" />
                </span>
                <span  class="text-over-flow">{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>

              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Product</span>
                <v-select
                  v-model="product_id"
                  :list="productList"
                  @change="handleChange"></v-select>
              </div>
            </template>
            <template slot-scope="scope">
              <span  class="text-over-flow">{{ getOptionName('productTypeOptions',scope.row.product_id)}}</span>
            </template>

          </el-table-column>
          <el-table-column
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Policyholder</span>
                <v-input
                  v-model="policy_holder"
                  placeholder="Search"
                  @change="handleChange"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.policy_holder}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="policy_no"
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Policy No.</span>
                <v-input
                  v-model="policy_no"
                  placeholder="Search"
                  @change="handleChange"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="link hand flex flex-aligin"
                @click="handlerPolicy(scope.row.order_no)">
                <font-awesome-icon icon="link"></font-awesome-icon>
                <span>{{ scope.row.policy_no||'-'}}</span>
              </span>
            </template>
          </el-table-column>


          <el-table-column
            prop="status"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Gross Premium</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.gross_premium|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Premium Received</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.amount_received|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==1"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Net Premium</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.insurer_net_vl|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Comm. to Referrer</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.referrer_commission_vl|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Status</span>
                <v-select
                  v-model="policy_status"
                  :list="policy_status_list"
                  @change="handleChange"></v-select>
              </div>
            </template>
            <template  slot-scope="scope">
              <v-button
                :class="getOptionName('buttonColorClassOption',scope.row.policy_status)"
                disabled
                plain>{{getOptionName('statusOptions',scope.row.policy_status)}}</v-button>
            </template>
          </el-table-column>


        </div>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <div>
                <el-row>
                  <v-row-col>
                    <span class="is-bold">Issue Date</span>
                    <v-date-picker
                      v-model="issueDate"
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      @change="handleChange"></v-date-picker>
                  </v-row-col>

                  <v-row-col>
                    <span class="is-bold">Effective Date</span>
                    <v-date-picker
                      v-model="effectiveDate"
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      @change="handleChange"></v-date-picker>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Settle Date</span>
                    <v-date-picker
                      v-model="settleDate"
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      @change="handleChange"></v-date-picker>
                  </v-row-col>
                  <v-row-col v-if="type==1">
                    <span class="is-bold">Expiry Date</span>
                    <v-date-picker
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                    ></v-date-picker>
                  </v-row-col>
                  <v-row-col v-if="type==2">
                    <span class="is-bold">Insurer</span>
                    <v-select
                      v-model='company_id'
                      :list="companyList"
                      @change="handleChange"></v-select>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Product</span>
                    <v-select
                      v-model="product_id"
                      :list="productList"
                      @change="handleChange"></v-select>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Policyholder</span>
                    <v-input
                      v-model="policy_holder"
                      placeholder="Search"
                      @change="handleChange"></v-input>
                  </v-row-col>

                  <v-row-col>
                    <span class="is-bold">Policy No.</span>
                    <v-input
                      v-model="policy_no"
                      placeholder="Search"
                      @change="handleChange"></v-input>
                  </v-row-col>

                  <!--                  <v-row-col >-->
                  <!--                    <span class="is-bold">Gross Premium</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <!--                  <v-row-col  v-if="type==2">-->
                  <!--                    <span class="is-bold">Premium Received</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <!--                  <v-row-col  v-if="type==2">-->
                  <!--                    <span class="is-bold">Comm. to Referrer</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <!--                  <v-row-col  v-if="type==1">-->
                  <!--                    <span class="is-bold">Net Premium</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <v-row-col >
                    <span class="is-bold">Status</span>
                    <v-select
                      v-model="policy_status"
                      :list="policy_status_list"
                      placeholder="Search"
                      @change="handleChange"></v-select>
                  </v-row-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table">
        <div
        >
          <div
            class="tr">
            <span>Issue Date</span>
            <span>{{showMonth(item.issue_date)}}</span>
          </div>

          <div
            class="tr">
            <span>Effective Date</span>
            <span>{{showMonth(item.effective_date)}}</span>
          </div>
          <div
            class="tr">
            <span>Settle Date</span>
            <span>{{showMonth(item.settle_date)}}</span>
          </div>
          <div
            v-if="type==1"
            class="tr">
            <span>Expiry Date</span>
            <span>{{showMonth(item.expiry_date)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Insurer</span>
            <span>{{item.insurer}}</span>
          </div>
          <div
            class="tr">
            <span>Product</span>
            <span>{{getOptionName('productTypeOptions',item.product_id)}}</span>
          </div>
          <div
            class="tr">
            <span>Policyholder</span>
            <span>{{item.policy_holder}}</span>
          </div>
          <div
            class="tr">
            <span>Policy No.</span>
            <span
              class="link"
              @click="handlerPolicy(item.order_no)">{{item.policy_no}}</span>
          </div>
          <div
            class="tr">
            <span>Gross Premium</span>
            <span>{{item.gross_premium|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Premium Received</span>
            <span>{{ item.amount_received|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Comm. to Referrer</span>
            <span>{{item.referrer_commission_vl|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==1"
            class="tr">
            <span>Net Premium</span>
            <span>{{item.insurer_net_vl|formatMoney(2,true)}}</span>
          </div>
          <div
            class="tr">
            <span>Status</span>
            <span>   <v-button
              :class="getOptionName('buttonColorClassOption',item.policy_status)"
              disabled
              plain>{{getOptionName('statusOptions',item.policy_status)}}</v-button></span>

          </div>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange"/>

  </section>
</template>

<script>
import markDialog from '../../components/dialog/markDialog'
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import {setInsurerSettle,getInsurerRecord} from '@/api/insurer'
import {setReferrerSettle,setReferrerUndo,getReferrerSettleSummary,getReferrerSettle} from '@api/referrer'
import {mapActions} from 'vuex'

export default {
  name: 'policiesSold',
  mixins: [MixinOptions,MixinFormat],
  props:{
    isSelect:{
      type:Boolean,
      default:true
    },
    type:{
      type:Number,
      default:1 //1 insurer 2 referral
    },
    activeName:{
      type:[String,Number],
      default:1 //1 polices 2 statement
    },
    summary:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    return {
      show:false,
      date:'',
      issueDate:'',
      effectiveDate:'',
      expiryDate:'',
      company_id:'',
      product_id:'',
      policy_holder:'',
      policy_no:'',
      policy_status:'',
      settleDate:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      multipleSelection: [],
      tableData:[]
    }
  },
  computed:{
    isAble(){
      return this.multipleSelection.length<=0
    },
    idResultList() {
      const orderList = this.multipleSelection.reduce((result, item) => {
        result.push(item.settle_id)
        return result
      }, [])
      return orderList
    },
    policy_status_list(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.policy_status)
    },
    companyList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.company)
    },
    productList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.product)
    }
  },
  created(){
    this.getList()
  },
  methods: {
    ...mapActions('statement', ['setStatement','setStatementParams']),
    handlerSelect(list) {
      list.forEach(item => {
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      })
    },
    handlerPolicy(order_no) {
      this.$router.push({
        path: '/quotation/premium/info',
        query: {
          order_no
        }
      })
    },
    handlerMark() {
      let params = {referral_id:this.$route.params.id, settle_id: this.idResultList,}
      this.$ConfirmBox({
        title: 'Undo Settlement',
        message: 'Are you sure you want to undo this settlement? This action cannot be reversed.',
        confirmBtn:'Undo Settlement',
        isCancel:true,
        classButton:true
      }).then(() => {
        this.network().setReferrerUndo(params)
      })
    },
    handlerClearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    getRowId (row) {
      return row.settle_id
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectable(row, index) {
      return row.is_settled == false
    },
    showOtherInsurer(company_id) {
      let arr =  [1,4,11,3]
      return arr.includes(company_id)
    },

    getList() {
      this.network().getReferrerSettle()
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size) {
      this.pageConfig.size = size
      this.getList()
    },
    handleChange(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    network() {
      return {
        setReferrerUndo:async (params) => {
          const { data } = await setReferrerUndo(params)
          this.tableData = []
          this.handlerClearSelection()
          this.handleChange()
        },
        getReferrerSettle: async (params) => {
          const {data} = await getReferrerSettle({
            referral_id: this.$route.params.id,
            size: this.pageConfig.size,
            page: this.pageConfig.currentPage,
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            effective_date_start:this.effectiveDate?this.effectiveDate[0]: '',
            effective_date_end:this.effectiveDate?this.effectiveDate[1]: '',
            settle_date_start:this.settleDate?this.settleDate[0]: '',
            settle_date_end:this.settleDate?this.settleDate[1]: '',
            policy_status:this.policy_status,
            policy_no:this.policy_no,
            policy_holder:this.policy_holder,
            product_id:this.product_id,
            company_id:this.company_id
          })
          let {meta} = data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../assets/css/policyList';
.flex-content{
  margin-bottom: 20px;
  .title-tip{
    font-size: 16px;
    font-weight: 600;
    .flex-cloumn:first-child{
      color: #000;
    }
    .flex-cloumn{
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      color: #4A5568;
      justify-content: space-between;
      .number{
        font-size: 28px;
        color: #718096;
      }
    }
  }
  .flex-button{
    align-self: flex-end;
  }
}
.flex-logo{
  align-items: center;
  .company-logo{
    width: 30px;
    height: 20px;
    display: flex;
    img{
      width: 60%;
      display: block;
    }
  }
}
::v-deep .table-slot-header{
  height: 71px!important;
}
.button-danger{
  background:rgb(211,76,70)!important;
  color: #fff;
}
</style>
